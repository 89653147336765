<template>
  <div class="layout">
    <NuxtPage/>
  </div>
</template>

<script>
export default {

};
</script>
